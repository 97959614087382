import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import "../stylesheets/pages/our-work.css"
import reimageITImg from "../../public/brand.png"
import mpfullImg from "../../public/MPfull_1.png"
import img1 from "../../public/reimageIT1.png"
import img2 from "../../public/reimageIT2.png"
import leftArrow from "../../public/left-arrow.svg"
import AnimatedElement from "../components/animated-element"
import Footer from "../components/footer.js"


export default function OurWork() {
  return (
    <Layout>
      <div id="OurWorkPage" className="mob">
        <AnimatedElement elem={<div className="divNest mob" id="outermost-ourwork-nest">
        <AnimatedElement elem={<div className="divNest alt mob">
        <AnimatedElement elem={<div className="divNest alt mob">
          <AnimatedElement elem={<div className="divNestBig mob">
            <div className="wideDiv mob">
              <div className="cardTop mob">
                <img className="cardImage mob" src={reimageITImg}/>
                <div className="projectType mob">
                  Web Application
                </div>
              </div>
              <div className="wideText mob">
                reimageIT is a university-level education platform centered around
                a unique Excel file grader that is significantly fast and more
                accurate than conventional Excel file grading methods.
              </div>
              <Link to="/casestudy/" className="cardLink">
                <div className="linkButton mob">
                  <div className="buttonText mob">
                      Case Study
                  </div>
                  <img className="buttonImg mob" src={leftArrow}/>
                </div>
              </Link>
            </div>
          </div>}  slideFromDirection="down" delay={0.6} duration={0.15} fadeIn={true} />
      </div>}  slideFromDirection="down" delay={0.45} duration={0.15} fadeIn={true} />
  </div>} slideFromDirection="down" delay={0.3} duration={0.15} fadeIn={true} />
</div>} slideFromDirection="down" delay={0.15} duration={0.15} fadeIn={true} />

        <div id="thinWrapper" className="mob">
          <AnimatedElement elem={<div className="divNestThin mob">
            <AnimatedElement elem={<div className="divNestThin alt mob">
              <AnimatedElement elem={<div className="divNestThin alt mob">
            <AnimatedElement elem={<div className="divNestBigThin mob">
              <div className="thinDiv mob">
                <img className="cardImageThin mob" src={mpfullImg}/>
                <div className="projectTypeThin mob">Mock-Up</div>
                <div className="thinText mob">
                  MatchPoint is a marketplace platform to create connections
                  between NCAA athletes and businesses.
                </div>
                <Link to="/screenshots/" className="cardLinkThin">
                  <div className="linkButtonThin mob">
                    <div className="buttonTextThin mob">Screenshots</div>
                    <img className="buttonImgThin mob" src={leftArrow}/>
                  </div>
                </Link>
              </div>
            </div>} slideFromDirection="down" delay={1.2} duration={0.15} fadeIn={true} />
        </div>} slideFromDirection="down" delay={1.05} duration={0.15} fadeIn={true} />
    </div>} slideFromDirection="down" delay={0.9} duration={0.15} fadeIn={true} />
</div>} slideFromDirection="down" delay={0.75} duration={0.15} fadeIn={true} />
        </div>
      </div>

      <div className="bottomHolder mob">
          <Footer color="white" marginTop="10%"/>
          <div id="moreComing" className="mob">
            more coming soon.
          </div>
      </div>
    </Layout>
  )
}
